<script>
  import {
    mdiEmailOutline,
    mdiEyeOffOutline,
    mdiEyeOutline,
    mdiHelpCircle,
    mdiLockCheckOutline,
    mdiLockOutline,
    mdiLockPlusOutline
  } from "@mdi/js";
  import { defineComponent, ref, watch } from "@vue/composition-api";
  // Using VeeValidate for validations
  import { ValidationObserver, ValidationProvider } from "vee-validate";

  import themeConfig from "@/themeConfig";
  import { useRegisterUser } from "@/views/apps/user/useUser";

  // Import Single File Components
  import AppHelpPopover from "../../components/base/help-popover/AppHelpPopover.vue";
  import { snackbar } from "../../components/base/snackbar/useSnackbar";

  export default defineComponent({
    name: "account-register",
    props: {
      email: {
        type: String,
        default: null
      },
      token: {
        type: String,
        default: null
      }
    },
    components: {
      AppHelpPopover,
      ValidationProvider,
      ValidationObserver
    },
    setup() {
      const form = ref(null);

      const {
        registrationForm,
        isPasswordVisible,
        isNewPasswordVisible,
        isConfirmPasswordVisible,
        loading,
        showPwnWarning,
        showRedirectMessage,
        onSubmit,
        resetForm
      } = useRegisterUser();

      const handleFormSubmit = async (registrationForm) => {
        onSubmit(registrationForm);
        form.value.reset();
      };

      const reset = async () => {
        try {
          resetForm();
        } catch (error) {
          snackbar({
            text: `Unable to clear form.`,
            color: "error"
          });
        } finally {
          form.value.reset();
        }
      };

      watch(showRedirectMessage, (value) => {
        value &&
          setTimeout(() => {
            showRedirectMessage.value = false;
          }, 3000);
      });
      return {
        form,
        registrationForm,
        isPasswordVisible,
        isNewPasswordVisible,
        isConfirmPasswordVisible,
        loading,
        showPwnWarning,
        showRedirectMessage,
        onSubmit,
        resetForm,
        handleFormSubmit,
        reset,
        icons: {
          mdiEmailOutline,
          mdiLockOutline,
          mdiLockPlusOutline,
          mdiLockCheckOutline,
          mdiEyeOutline,
          mdiEyeOffOutline,
          mdiHelpCircle
        },
        appName: themeConfig.app.name,
        appLogo: themeConfig.app.logo
      };
    }
  });
</script>
<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <validation-observer ref="form" v-slot="{ invalid, reset }">
          <!-- logo -->
          <v-card-title class="d-flex align-center justify-center py-7">
            <router-link to="/" class="d-flex align-center">
              <v-img
                :src="appLogo"
                max-height="30px"
                max-width="30px"
                height="30px"
                width="30px"
                alt="logo"
                contain
                class="me-3"
              ></v-img>

              <h2 class="text-2xl font-weight-semibold">
                {{ appName }}
              </h2>
            </router-link>
          </v-card-title>

          <!-- title -->
          <v-card-text>
            <p
              class="text-xl font-weight-semibold text--primary text-center mb-2"
            >
              Get started here 🚀
            </p>
          </v-card-text>

          <!-- registration form -->
          <v-card-text>
            <form
              @submit.prevent="handleFormSubmit(registrationForm)"
              @reset.prevent="reset"
            >
              <!-- email field: pre-populated -->
              <div class="mb-2">
                <validation-provider
                  v-slot="{ errors }"
                  name="Email Address"
                  rules="required"
                >
                  <label
                    for="email"
                    class="label-color font-weight-semibold d-block"
                  >
                    Email
                  </label>
                  <v-text-field
                    id="email"
                    v-model="registrationForm.email"
                    dense
                    outlined
                    required
                    disabled
                    :prepend-inner-icon="icons.mdiEmailOutline"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </div>

              <!-- current password: sent via email invite -->
              <div class="mb-2">
                <validation-provider
                  v-slot="{ errors }"
                  name="Current Password"
                  vid="password"
                  rules="required"
                >
                  <label
                    for="password"
                    class="label-color font-weight-semibold d-block"
                  >
                    Current Password
                  </label>
                  <v-text-field
                    id="password"
                    v-model.trim="registrationForm.password"
                    :type="isPasswordVisible ? 'text' : 'password'"
                    dense
                    outlined
                    clearable
                    :prepend-inner-icon="icons.mdiLockOutline"
                    :append-icon="
                      isPasswordVisible
                        ? icons.mdiEyeOffOutline
                        : icons.mdiEyeOutline
                    "
                    required
                    :error-messages="errors"
                    @click:append="isPasswordVisible = !isPasswordVisible"
                  ></v-text-field>
                </validation-provider>
              </div>

              <!-- new password -->
              <div class="mb-3">
                <validation-provider
                  v-slot="{ errors }"
                  mode="lazy"
                  name="New Password"
                  vid="newPassword"
                  rules="required|regexStrongPassword|preventTempPasswordReuse:@password"
                >
                  <label
                    class="d-flex justify-space-between align-center font-weight-semibold"
                    for="newPassword"
                  >
                    New Password
                    <app-help-popover>
                      <template v-slot:btn-title>
                        <span class="info--text font-weight-semibold">
                          Password Requirements
                        </span>
                      </template>
                      <template v-slot:help-popover-content>
                        <span class="font-weight-semibold">
                          Passwords must:
                        </span>
                        <ul>
                          <li>Be at least 8 characters long</li>
                          <li>Have at least one uppercase character</li>
                          <li>Have at least one lowercase character</li>
                          <li>Have at least one special character</li>
                        </ul>
                      </template>
                    </app-help-popover>
                  </label>
                  <v-text-field
                    v-model.trim="registrationForm.newPassword"
                    :type="isNewPasswordVisible ? 'text' : 'password'"
                    dense
                    outlined
                    clearable
                    :prepend-inner-icon="icons.mdiLockOutline"
                    :append-icon="
                      isNewPasswordVisible
                        ? icons.mdiEyeOffOutline
                        : icons.mdiEyeOutline
                    "
                    required
                    :error-messages="errors"
                    @click:append="isNewPasswordVisible = !isNewPasswordVisible"
                  ></v-text-field>
                </validation-provider>
              </div>

              <!-- confirm new password -->
              <div class="mb-3">
                <validation-provider
                  v-slot="{ errors }"
                  name="Password Confirmation"
                  vid="confirmPassword"
                  rules="required|confirmPassword:@newPassword"
                >
                  <label
                    for="confirmPassword"
                    class="label-color font-weight-semibold d-block"
                  >
                    Confirm Password
                  </label>
                  <v-text-field
                    v-model.trim="registrationForm.confirmPassword"
                    :type="isConfirmPasswordVisible ? 'text' : 'password'"
                    dense
                    outlined
                    clearable
                    :prepend-inner-icon="icons.mdiLockOutline"
                    :append-icon="
                      isConfirmPasswordVisible
                        ? icons.mdiEyeOffOutline
                        : icons.mdiEyeOutline
                    "
                    :error-messages="errors"
                    @click:append="
                      isConfirmPasswordVisible = !isConfirmPasswordVisible
                    "
                  ></v-text-field>
                </validation-provider>
              </div>
              <v-btn
                block
                class="mt-6"
                type="submit"
                color="primary"
                :disabled="invalid"
              >
                Complete Registration
              </v-btn>
            </form>
          </v-card-text>

          <!-- <div v-else class="login-wrapper">
              <v-overlay :value="showRedirectMessage">
                <v-progress-circular
                  indeterminate
                  size="64"
                ></v-progress-circular>
              </v-overlay>
            </div> -->
        </validation-observer>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="
        require(`@/assets/images/misc/mask-${
          $vuetify.theme.dark ? 'dark' : 'light'
        }.png`)
      "
    />

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<style lang="scss">
  @import "@/preset/preset/pages/auth.scss";
</style>
