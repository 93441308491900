<script>
  import { mdiHelpCircle } from "@mdi/js";
  import { defineComponent } from "@vue/composition-api";

  import { appHelpPopover } from "./useAppHelpPopover";

  export default defineComponent({
    name: "app-help-popover",
    setup() {
      const { menu } = appHelpPopover();

      return {
        menu,
        icons: {
          mdiHelpCircle
        }
      };
    }
  });
</script>
<template>
  <v-menu v-model="menu" close-on-content-click nudge-width="200" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn small plain v-bind="attrs" v-on="on">
        <v-icon small color="info">
          {{ icons.mdiHelpCircle }}
        </v-icon>
        <slot name="btn-title"></slot>
      </v-btn>
    </template>
    <v-card class="pa-3" dark>
      <slot name="help-popover-content"></slot>
    </v-card>
  </v-menu>
</template>
