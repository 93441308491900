var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1"},[_c('div',{staticClass:"auth-inner"},[_c('v-card',{staticClass:"auth-card"},[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var reset = ref.reset;
return [_c('v-card-title',{staticClass:"d-flex align-center justify-center py-7"},[_c('router-link',{staticClass:"d-flex align-center",attrs:{"to":"/"}},[_c('v-img',{staticClass:"me-3",attrs:{"src":_vm.appLogo,"max-height":"30px","max-width":"30px","height":"30px","width":"30px","alt":"logo","contain":""}}),_c('h2',{staticClass:"text-2xl font-weight-semibold"},[_vm._v(" "+_vm._s(_vm.appName)+" ")])],1)],1),_c('v-card-text',[_c('p',{staticClass:"text-xl font-weight-semibold text--primary text-center mb-2"},[_vm._v(" Get started here 🚀 ")])]),_c('v-card-text',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleFormSubmit(_vm.registrationForm)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('div',{staticClass:"mb-2"},[_c('validation-provider',{attrs:{"name":"Email Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-color font-weight-semibold d-block",attrs:{"for":"email"}},[_vm._v(" Email ")]),_c('v-text-field',{attrs:{"id":"email","dense":"","outlined":"","required":"","disabled":"","prepend-inner-icon":_vm.icons.mdiEmailOutline,"error-messages":errors},model:{value:(_vm.registrationForm.email),callback:function ($$v) {_vm.$set(_vm.registrationForm, "email", $$v)},expression:"registrationForm.email"}})]}}],null,true)})],1),_c('div',{staticClass:"mb-2"},[_c('validation-provider',{attrs:{"name":"Current Password","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-color font-weight-semibold d-block",attrs:{"for":"password"}},[_vm._v(" Current Password ")]),_c('v-text-field',{attrs:{"id":"password","type":_vm.isPasswordVisible ? 'text' : 'password',"dense":"","outlined":"","clearable":"","prepend-inner-icon":_vm.icons.mdiLockOutline,"append-icon":_vm.isPasswordVisible
                      ? _vm.icons.mdiEyeOffOutline
                      : _vm.icons.mdiEyeOutline,"required":"","error-messages":errors},on:{"click:append":function($event){_vm.isPasswordVisible = !_vm.isPasswordVisible}},model:{value:(_vm.registrationForm.password),callback:function ($$v) {_vm.$set(_vm.registrationForm, "password", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"registrationForm.password"}})]}}],null,true)})],1),_c('div',{staticClass:"mb-3"},[_c('validation-provider',{attrs:{"mode":"lazy","name":"New Password","vid":"newPassword","rules":"required|regexStrongPassword|preventTempPasswordReuse:@password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('label',{staticClass:"d-flex justify-space-between align-center font-weight-semibold",attrs:{"for":"newPassword"}},[_vm._v(" New Password "),_c('app-help-popover',{scopedSlots:_vm._u([{key:"btn-title",fn:function(){return [_c('span',{staticClass:"info--text font-weight-semibold"},[_vm._v(" Password Requirements ")])]},proxy:true},{key:"help-popover-content",fn:function(){return [_c('span',{staticClass:"font-weight-semibold"},[_vm._v(" Passwords must: ")]),_c('ul',[_c('li',[_vm._v("Be at least 8 characters long")]),_c('li',[_vm._v("Have at least one uppercase character")]),_c('li',[_vm._v("Have at least one lowercase character")]),_c('li',[_vm._v("Have at least one special character")])])]},proxy:true}],null,true)})],1),_c('v-text-field',{attrs:{"type":_vm.isNewPasswordVisible ? 'text' : 'password',"dense":"","outlined":"","clearable":"","prepend-inner-icon":_vm.icons.mdiLockOutline,"append-icon":_vm.isNewPasswordVisible
                      ? _vm.icons.mdiEyeOffOutline
                      : _vm.icons.mdiEyeOutline,"required":"","error-messages":errors},on:{"click:append":function($event){_vm.isNewPasswordVisible = !_vm.isNewPasswordVisible}},model:{value:(_vm.registrationForm.newPassword),callback:function ($$v) {_vm.$set(_vm.registrationForm, "newPassword", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"registrationForm.newPassword"}})]}}],null,true)})],1),_c('div',{staticClass:"mb-3"},[_c('validation-provider',{attrs:{"name":"Password Confirmation","vid":"confirmPassword","rules":"required|confirmPassword:@newPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('label',{staticClass:"label-color font-weight-semibold d-block",attrs:{"for":"confirmPassword"}},[_vm._v(" Confirm Password ")]),_c('v-text-field',{attrs:{"type":_vm.isConfirmPasswordVisible ? 'text' : 'password',"dense":"","outlined":"","clearable":"","prepend-inner-icon":_vm.icons.mdiLockOutline,"append-icon":_vm.isConfirmPasswordVisible
                      ? _vm.icons.mdiEyeOffOutline
                      : _vm.icons.mdiEyeOutline,"error-messages":errors},on:{"click:append":function($event){_vm.isConfirmPasswordVisible = !_vm.isConfirmPasswordVisible}},model:{value:(_vm.registrationForm.confirmPassword),callback:function ($$v) {_vm.$set(_vm.registrationForm, "confirmPassword", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"registrationForm.confirmPassword"}})]}}],null,true)})],1),_c('v-btn',{staticClass:"mt-6",attrs:{"block":"","type":"submit","color":"primary","disabled":invalid}},[_vm._v(" Complete Registration ")])],1)])]}}])})],1)],1),_c('img',{staticClass:"auth-mask-bg",attrs:{"height":"190","src":require(("@/assets/images/misc/mask-" + (_vm.$vuetify.theme.dark ? 'dark' : 'light') + ".png"))}}),_c('v-img',{staticClass:"auth-tree",attrs:{"width":"247","height":"185","src":require("@/assets/images/misc/tree.png")}}),_c('v-img',{staticClass:"auth-tree-3",attrs:{"width":"377","height":"289","src":require("@/assets/images/misc/tree-3.png")}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }